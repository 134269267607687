import { onAuthStateChanged } from 'firebase/auth';
import { useMemo, useState, useEffect, useCallback } from 'react';

import { useSetState } from 'src/hooks/use-set-state';

import axios from 'src/utils/axios';

import { AUTH } from 'src/lib/firebase';
import { getUserData } from 'src/actions/polices';

import { AuthContext } from '../auth-context';

// ----------------------------------------------------------------------

export function AuthProvider({ children }) {
  const { state, setState } = useSetState({
    user: null,
    loading: true,
  });

  const [userData, setUserData] = useState(null);

  const checkUserSession = useCallback(async () => {
    try {
      const unsubscribe = onAuthStateChanged(AUTH, async (user) => {
        if (user) {
          const { accessToken, email } = user;

          axios.defaults.headers.common['x-api-key'] = `${accessToken}`;

          // Fetch additional user data from your backend
          try {
            localStorage.setItem('user_id', user.uid);

            // Check if clarity is defined
            if (window?.clarity) {
              // Set user properties
              window.clarity('set', 'unique_id', user.uid.slice(-5));
            }

            const response = await getUserData(user.uid);
            setUserData(response);
          } catch (error) {
            console.error('Error fetching user data:', error);
          }
          setState({ user: { ...user }, loading: false });
        } else {
          setState({ user: null, loading: false });
          setUserData(null);
          delete axios.defaults.headers.common['x-api-key'];
        }
      });

      // Return the unsubscribe function
      return unsubscribe;
    } catch (error) {
      console.error(error);
      setState({ user: null, loading: false });
      setUserData(null);
    }
  }, [setState]);

  useEffect(() => {
    const unsubscribe = checkUserSession();
    // Cleanup function to unsubscribe when component unmounts
    return () => {
      if (unsubscribe && typeof unsubscribe === 'function') {
        unsubscribe();
      }
    };
  }, [checkUserSession]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user
        ? {
            ...state.user,
            id: state.user?.uid,
            accessToken: state.user?.accessToken,
            displayName: state.user?.displayName,
            photoURL: state.user?.photoURL,
            role: state.user?.role ?? 'admin',
          }
        : null,
      userData,
      checkUserSession,
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
    }),
    [checkUserSession, state.user, status, userData]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
